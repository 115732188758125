import React from 'react'
import ScirrelImg from '../../img/scrirrel.png'
import PortfolioImg from '../../img/portfolio.png'
import BXIVImg from '../../img/bxiv.jpg'
import Fancare from '../../img/fancare.png'
import { SiMongodb, SiReact, SiExpressvpn, SiNodedotjs, SiTailwindcss, SiAmazonaws, SiStripe } from "react-icons/si";

const Home = () => {
  return (
    <>
    <div className='mh-50vh bg--color'>
      <div className="flex justify-center ">
        <div className="w-1/2.5 flex flex-col justify-center items-start opacity-60 mt-10 h-50vh">
          <h1 className="text-brand-color text-5xl md:text-8xl ">Calum Marshall</h1>
          <h2 className="text-mid-color text-3xl text-center md:text-5xl ">CS Graduate & Web Developer</h2>
        </div>

        <div className="hidden  pr-5vw w-1/2 opacity-50 h-60vh">
          <p className="clip-background">Storys to rede ar delitabill,
            Suppos that thai be nocht bot fabill:
            Than suld storys that suthfast wer,
            And thai war said on gud maner,
            Have doubill plesance in heryng.
            The fyrst plesance is the carpyng,
            And the tothir the suthfastnes
            That schawys the thing rycht as it wes:
            And suth thyngis that ar likand
            Tyll mannys heryng ar plesand.
            Tharfor I wald fayne set my will,
            Giff my wyt mycht suffice thartill,
            To put in wryt a suthfast story,
            That it lest ay furth in memory,
            Swa that na tyme of lenth it let,
            Na ger it haly be forget.
            For aulde storys that men redys,
            Representis to thaim the dedys
            Of stalwart folk that lyvyt ar,
            Rycht as thai than in presence war.
            And certis, thai suld weill have prys
            That in thar tyme war wycht and wys,
            And led thar lyff in gret travaill,
            And oft, in hard stour off bataill,
            Wan richt gret price off chevalry,
            And war voydyt off cowardy.
            As wes King Robert off Scotland,
            That hardy wes off hart and hand;
            And gud Schyr James off Douglas,
            That in his tyme sa worthy was,
            That off hys price and hys bounte,
            In fer landis renownyt wes he.
            Off thaim I thynk this buk to ma:
            Now God gyff grace that I may swa
            Tret it, and bryng it till endyng,
            That I say nocht bot suthfast thing!
            
            Quhen Alexander the King was deid,
            That Scotland haid to steyr and leid,
            The land sex yher, and mayr perfay,
            Lay desolat eftyr hys day;
            Till that the barnage at the last
            Assemblyt thaim, and fayndyt fast
            To cheys a king thar land to ster,
            That, off awncestry, cummyn wer
            Off kingis that aucht that reawte,
            And mayst had rycht thair king to be.
            Bot envy, that is sa feloune,
            Maid amang thaim discencioun.
            For sum wald haiff the Balleoll king;
            For he wes cummyn off the offspryng
            Off hyr that eldest systir was.
            And othir sum nyt all that cas;
            And said, that he thair king suld be
            That wes in als nere degre,
            And cummyn wes of the neist male,
            And in branch collaterale.
            Thai said, successioun of kyngrik
            Was nocht to lawer feys lik;
            For thar mycht succed na female,
            Quhill foundyn mycht be ony male
            That were in lyne evyn descendand;
            Thai bar all othir wayis on hand,
            For than the neyst cummyn off the seid,
            Man or woman, suld succeid.
            Be this resoun that part thocht hale,
            That the lord off Anandyrdale,
            Robert the Bruys Erle off Carryk,
            Aucht to succeid to the kynryk.
            The barownys thus war at discord,
            That on na maner mycht accord
            Till at the last thai all concordyt,
            That all thar spek suld be recordyt
            
            Storys to rede ar delitabill,
            Suppos that thai be nocht bot fabill:
            Than suld storys that suthfast wer,
            And thai war said on gud maner,
            Have doubill plesance in heryng.
            The fyrst plesance is the carpyng,
            And the tothir the suthfastnes
            That schawys the thing rycht as it wes:
            And suth thyngis that ar likand
            Tyll mannys heryng ar plesand.
            Tharfor I wald fayne set my will,
            Giff my wyt mycht suffice thartill,
            To put in wryt a suthfast story,
            That it lest ay furth in memory,
            Swa that na tyme of lenth it let,
            Na ger it haly be forget.
            For aulde storys that men redys,
            Representis to thaim the dedys
            Of stalwart folk that lyvyt ar,
            Rycht as thai than in presence war.
            And certis, thai suld weill have prys
            That in thar tyme war wycht and wys,
            And led thar lyff in gret travaill,
            And oft, in hard stour off bataill,
            Wan richt gret price off chevalry,
            And war voydyt off cowardy.
            As wes King Robert off Scotland,
            That hardy wes off hart and hand;
            And gud Schyr James off Douglas,
            That in his tyme sa worthy was,
            That off hys price and hys bounte,
            In fer landis renownyt wes he.
            Off thaim I thynk this buk to ma:
            Now God gyff grace that I may swa
            Tret it, and bryng it till endyng,
            That I say nocht bot suthfast thing!
            
            Quhen Alexander the King was deid,
            That Scotland haid to steyr and leid,
            The land sex yher, and mayr perfay,
            Lay desolat eftyr hys day;
            Till that the barnage at the last
            Assemblyt thaim, and fayndyt fast
            To cheys a king thar land to ster,
            That, off awncestry, cummyn wer
            Off kingis that aucht that reawte,
            And mayst had rycht thair king to be.
            Bot envy, that is sa feloune,
            Maid amang thaim discencioun.
            For sum wald haiff the Balleoll king;
            For he wes cummyn off the offspryng
            Off hyr that eldest systir was.
            And othir sum nyt all that cas;
            And said, that he thair king suld be
            That wes in als nere degre,
            And cummyn wes of the neist male,
            And in branch collaterale.
            Thai said, successioun of kyngrik
            Was nocht to lawer feys lik;
            For thar mycht succed na female,
            Quhill foundyn mycht be ony male
            That were in lyne evyn descendand;
            Thai bar all othir wayis on hand,
            For than the neyst cummyn off the seid,
            Man or woman, suld succeid.
            Be this resoun that part thocht hale,
            That the lord off Anandyrdale,
            Robert the Bruys Erle off Carryk,
            Aucht to succeid to the kynryk.
            The barownys thus war at discord,
            That on na maner mycht accord
            Till at the last thai all concordyt,
            That all thar spek suld be recordyt
            
            Storys to rede ar delitabill,
            Suppos that thai be nocht bot fabill:
            Than suld storys that suthfast wer,
            And thai war said on gud maner,
            Have doubill plesance in heryng.
            The fyrst plesance is the carpyng,
            And the tothir the suthfastnes
            That schawys the thing rycht as it wes:
            And suth thyngis that ar likand
            Tyll mannys heryng ar plesand.
            Tharfor I wald fayne set my will,
            Giff my wyt mycht suffice thartill,
            To put in wryt a suthfast story,
            That it lest ay furth in memory,
            Swa that na tyme of lenth it let,
            Na ger it haly be forget.
            For aulde storys that men redys,
            Representis to thaim the dedys
            Of stalwart folk that lyvyt ar,
            Rycht as thai than in presence war.
            And certis, thai suld weill have prys
            That in thar tyme war wycht and wys,
            And led thar lyff in gret travaill,
            And oft, in hard stour off bataill,
            Wan richt gret price off chevalry,
            And war voydyt off cowardy.
            As wes King Robert off Scotland,
            That hardy wes off hart and hand;
            And gud Schyr James off Douglas,
            That in his tyme sa worthy was,
            That off hys price and hys bounte,
            In fer landis renownyt wes he.
            Off thaim I thynk this buk to ma:
            Now God gyff grace that I may swa
            Tret it, and bryng it till endyng,
            That I say nocht bot suthfast thing!
            
            Quhen Alexander the King was deid,
            That Scotland haid to steyr and leid,
            The land sex yher, and mayr perfay,
            Lay desolat eftyr hys day;
            Till that the barnage at the last
            Assemblyt thaim, and fayndyt fast
            To cheys a king thar land to ster,
            That, off awncestry, cummyn wer
            Off kingis that aucht that reawte,
            And mayst had rycht thair king to be.
            Bot envy, that is sa feloune,
            Maid amang thaim discencioun.
            For sum wald haiff the Balleoll king;
            For he wes cummyn off the offspryng
            Off hyr that eldest systir was.
            And othir sum nyt all that cas;
            And said, that he thair king suld be
            That wes in als nere degre,
            And cummyn wes of the neist male,
            And in branch collaterale.
            Thai said, successioun of kyngrik
            Was nocht to lawer feys lik;
            For thar mycht succed na female,
            Quhill foundyn mycht be ony male
            That were in lyne evyn descendand;
            Thai bar all othir wayis on hand,
            For than the neyst cummyn off the seid,
            Man or woman, suld succeid.
            Be this resoun that part thocht hale,
            That the lord off Anandyrdale,
            Robert the Bruys Erle off Carryk,
            Aucht to succeid to the kynryk.
            The barownys thus war at discord,
            That on na maner mycht accord
            Till at the last thai all concordyt,
            That all thar spek suld be recordyt</p>
        </div>
       
      </div>
      

    </div>
    <div className='mh-50vh bg--color py-10vh '>
        <h2 className='text-2xl'>Projects</h2>

        <p className='text-mid-color text-lg'>You can find some of my projects below.</p>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  p-10 gap-20'>

        <div className='bg-mid-color rounded-lg py-4 px-2  flex flex-col justify-between'>
              <a href="https://www.fancare.app" target="_blank" rel="noreferrer"><h3 className='text-xl pb-2 hover:text-brand-color hover:opacity-80'>Fancare App</h3></a>
              <p className='opacity-60 '>The Ultimate Platform for Creator Support.</p>
              <div className="flex-col md:flex-row flex justify-center  p-4 items-center opacity-80 flex-wrap">   
                <div className="flex justify-center gap-4 p-4 items-center p-0 "> <SiReact className='text-brand-color text-3xl'/> <p>React</p></div>           
                <div className="flex justify-center gap-4 p-4 items-center "> <SiExpressvpn className='text-brand-color text-3xl'/> <p>Express</p></div>           
                <div className="flex justify-center gap-4 p-4 items-center "><SiMongodb className='text-brand-color text-3xl'/> <p>MongoDB</p></div>           
                <div className="flex justify-center gap-4 p-4 items-center "><SiNodedotjs className='text-brand-color text-3xl'/> <p>Node.js</p></div>  
                <div className="flex justify-center gap-4 p-4 items-center "><SiAmazonaws className='text-brand-color text-3xl'/> <p>AWS</p></div>     
                <div className="flex justify-center gap-4 p-4 items-center "><SiStripe className='text-brand-color text-3xl'/> <p>Stripe</p></div>     
                <div className="flex justify-center gap-4 p-4 items-center "><SiTailwindcss className='text-brand-color text-3xl'/> <p>Tailwind</p></div>     

        
              </div>
              <a href="https://www.fancare.app/" target="_blank" rel="noreferrer"><img src={Fancare} className='h-60 w-full object-cover object-top rounded-lg  hover:opacity-60 ' alt="Scrirrel" /></a>
          </div>


          <div className='bg-mid-color rounded-lg py-4 px-2 flex flex-col justify-between'>
              <a href="https://www.scrirrel.com" target="_blank" rel="noreferrer"><h3 className='text-xl pb-2 hover:text-brand-color hover:opacity-80'>Scrirrel</h3></a>
              <p className='opacity-60 '>A Collaborative Creative Writing App.</p>
              <div className="flex-col md:flex-row flex justify-center  p-4 items-center opacity-80 flex-wrap">   
                <div className="flex justify-center gap-4 p-4 items-center p-0 "> <SiReact className='text-brand-color text-3xl'/> <p>React</p></div>           
                <div className="flex justify-center gap-4 p-4 items-center "> <SiExpressvpn className='text-brand-color text-3xl'/> <p>Express</p></div>           
                <div className="flex justify-center gap-4 p-4 items-center "><SiMongodb className='text-brand-color text-3xl'/> <p>MongoDB</p></div>           
                <div className="flex justify-center gap-4 p-4 items-center "><SiNodedotjs className='text-brand-color text-3xl'/> <p>Node.js</p></div>   
                       
              </div>
              <a href="https://www.scrirrel.com" target="_blank" rel="noreferrer"><img src={ScirrelImg} className='h-60 w-full object-cover object-top rounded-lg  hover:opacity-60 ' alt="Scrirrel" /></a>
          </div>

          

          <div className='bg-mid-color rounded-lg py-4 px-2 flex flex-col justify-between'>
              <a href="https://britanniaxiv.com" target="_blank" rel="noreferrer"><h3 className='text-xl pb-2 hover:text-brand-color hover:opacity-80'>Britannia XIV</h3></a>
              <p className='opacity-60 '>A Medieval Reenactment Website.</p>
              <div className="flex-col md:flex-row flex justify-center  p-4 items-center opacity-80 flex-wrap">   
                <div className="flex justify-center gap-4 p-4 items-center p-0 "> <SiReact className='text-brand-color text-3xl'/> <p>React</p></div>           
                <div className="flex justify-center gap-4 p-4 items-center "> <SiExpressvpn className='text-brand-color text-3xl'/> <p>Express</p></div>           
                <div className="flex justify-center gap-4 p-4 items-center "><SiMongodb className='text-brand-color text-3xl'/> <p>MongoDB</p></div>           
                <div className="flex justify-center gap-4 p-4 items-center "><SiNodedotjs className='text-brand-color text-3xl'/> <p>Node.js</p></div>          
              </div>
              <a href="https://britanniaxiv.com" target="_blank" rel="noreferrer"><img src={BXIVImg} className='h-60 w-full object-cover object-top rounded-lg  hover:opacity-60 ' alt="Britannia XIV" /></a>
          </div>

          <div className='bg-mid-color rounded-lg py-4 px-2    flex flex-col justify-between'>
              <a href="https://www.calummarshall.com" target="_blank" rel="noreferrer"><h3 className='text-xl pb-2  hover:text-brand-color hover:opacity-80'>Calum Marshall Portfolio</h3></a>
              <p className='opacity-60 '>A Website for my personal projects and web development work.</p>
              <div className="flex-col md:flex-row  flex justify-center gap-4 p-4 items-center opacity-80">              
                <SiReact className='text-brand-color text-3xl'/> <p>React</p>
                <SiTailwindcss className='text-brand-color text-3xl'/> <p>TailwindCSS</p>

              </div>

              <a href="https://www.calummarshall.com" target="_blank" rel="noreferrer"><img src={PortfolioImg} className='h-60 w-full object-cover object-top rounded-lg hover:opacity-60 ' alt="Scrirrel" /></a>
          </div>

          
        </div>

       
    </div>
    </>
  )
}

export default Home