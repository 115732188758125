import React from 'react'


const Banner = () => {
  return (
    <div className='flex flex-col space-between justify-center items-center h-50vh'>
          <h1 className="text-3xl md:text-5xl text-light-color opacity-80">Web Development</h1>
          <h2 className="text-mid-color text-lg p-4 ">I will build you a website that promotes your brand and gets you customers.</h2>
    </div>
  )
}

export default Banner