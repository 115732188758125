import React, {useState} from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
    <nav className='flex justify-between flex-col md:flex-row w-90vw py-4 pl-10vw'>

      <div className='flex justify-between w-full md:w-auto'>
        <div className="brand text-brand-color opacity-60 text-xl ">
          <Link to='/'><h1>Calum Marshall</h1></Link>

        </div>
        <button  type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-brand-color " onClick = {() => setIsExpanded(!isExpanded)}>
          <span class="sr-only">Open menu</span>
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>
      </div>
        <div  class={isExpanded ? "flex flex-col md:flex-row text-right pt-4" : " hidden w-full md:flex md:w-auto"}  >
            <Link  to='/'>Portfolio</Link>
            <Link className="pl-10"to='/web'>Website Development</Link>
        </div>

    </nav>
    </>

  )
}

export default Navbar