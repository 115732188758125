import React from 'react'
import { FaLinkedin, FaGithubSquare } from "react-icons/fa";


const Footer = () => {
  return (
    <footer>
      <div>
        <h2 className='text-xl'>Get in Touch!</h2>
        <div className="flex gap-2 justify-center text-brand-color   text-4xl py-4">
          <a href="https://www.linkedin.com/in/calum-marshall-195045a6" target="_blank"  rel="noreferrer"className=' opacity-60 hover:opacity-100'><FaLinkedin/></a>
          <a href="https://github.com/cmarsh27" target="_blank" rel="noreferrer"  className=' opacity-60 hover:opacity-100'><FaGithubSquare/></a>
        </div>


        <h3 className="text-mid-color py-4">Calum Marshall ©</h3>
      </div>


    </footer>
  )
}

export default Footer