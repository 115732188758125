import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

import Home from './pages/Home/Home';
import Web from './pages/Web/Web';

function App() {
  return (
    <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path ="/" exact element={<Home />}/>
          <Route path ="/web" exact element={<Web />}/>

        </Routes>
        <Footer/>
    </BrowserRouter>
  );
}

export default App;
