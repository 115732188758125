import React, {useState} from 'react'


const ContactForm = () => {
    const [formData, setFormData] = useState({website: 'CalumMarshall', check: 'thisisthecheck123', name: '', email: '', body : ''})
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch('https://cmemail.herokuapp.com/', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
            },
            body: JSON.stringify({website: formData.website, check: formData.check, name: formData.name, email: formData.email, body: formData.body, subject: `Message from ${formData.name}`})

        })
        setMessage('Message sent. Thanks for getting in touch!')
        clearData();
    }

    const clearData = () => {
        setFormData({
            website: 'CalumMarshall', check: 'thisisthecheck123', name: '', email: '', body: ''
        })

    }

  return (
    <>

    <form className="text-left bg-mid-color shadow-md rounded-lg w-80vw  md:w-60vw lg:w-30vw px-8 pt-6 pb-8 mb-4" action="https://api.web3forms.com/submit" method="POST">
        <input type="hidden" name="access_key" value="9dc17e59-f1bd-411b-8d96-92c53811213f"/>
        <div className="mb-4">
            <p className='text-brand-color opacity-80 text-md mb-4'>{message}</p>
        <label className="block text-md mb-2" for="name">
            Name
        </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-mid-color leading-tight focus:outline-none focus:shadow-outline" id="name" name="name" type="text" placeholder="John Smith"/>
        </div>
        <div className="mb-4">
        <label className="block text-md mb-2" for="email">
            Email
        </label>
        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-mid-color leading-tight focus:outline-none focus:shadow-outline" id="email" name="email" type="email" required placeholder="johnsmith@gmail.com" />
        </div>
        <div className="mb-4">
        <label className="block text-md mb-2" for="message">
            Message
        </label>
        <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-mid-color leading-tight focus:outline-none focus:shadow-outline mb-4" id="message" name="message" type="message" required placeholder="Hello Calum, I'm interested in a website."/>
        </div>
        <input type="hidden" name="redirect" value="https://web3forms.com/success"/>

        <div className="flex items-center justify-between">
        <button className='border-2 border-brand-color hover:bg-brand-color w-full  mb-4 py-4 px-8 rounded-xl' type="submit" >
            Submit
        </button>
        </div>
    </form>
    
    </>
  )
}

export default ContactForm