import React, {useRef} from 'react'
import Banner from '../../components/Banner/Banner'
import ContactForm from '../../components/ContactForm/ContactForm';

const Web = () => {
  const contactRef = useRef();

  function handleScroll(){
    contactRef.current.scrollIntoView({behavior: 'smooth'});
  }
  return (
    <>
      <Banner/>
      <section className='p-10 flex justify-center'>
        <div className='w-80vw md:w-40vw text-left'>
          <h2 className='text-light-color text-2xl opacity-80 py-2 '>Website Design </h2>
          <p className="text-mid-color">Most of my clients are creative individuals/organisations and local businesses. I specialise in <span className='text-brand-color opacity-80'>React</span> development, but I'm happy to discuss other options - I have experience building <span className='text-brand-color opacity-80'>Wordpress</span> websites and other full stack applications.  </p>
          <div className='flex'>

          </div>
        </div>


      </section>

    {/** 
      <section className='p-10vh flex flex-col items-center'>
        <h2 className='text-light-color text-2xl  py-8 '>Packages</h2>

        <div className='flex flex-col md:flex-row justify-center content-center  gap-2 w-80vw lg:w-60vw'>

          <div className='bg-mid-color rounded-lg py-4 px-2 w-full md:w-1/3 lg:w-1/3  flex flex-col items-center justify-between shadow-md'>
            <h4 className='text-xl py-4'>Basic</h4>
            <div className='text-md pt-1 pb-0 '>
              <span className='text-dark-color font-light px-1'>from</span>
            </div>
            <div className='flex text-5xl pb-4'>
              <span className='text-dark-color opacity-80 font-light px-1'>£</span>
              <span >445</span>
            </div>
            <p>UI Design</p>
            <p>Up to 3 Pages</p>
            <p></p>
            <button className='border-2 border-brand-color hover:bg-brand-color  mt-10  mb-4 py-4 px-8 rounded-xl' onClick={handleScroll}>Contact me</button>
          </div>

          <div className='bg-shade-brand-color rounded-lg py-4 px-2 w-full md:w-1/3  flex flex-col items-center justify-between shadow-md'>
            <h4 className='text-xl py-4'>Premium</h4>
            <div className='text-md pt-1 pb-0 '>
              <span className='text-dark-color font-light px-1'>from</span>
            </div>
            <div className='flex text-5xl pb-4'>
              <span className='text-dark-color opacity-80 font-light px-1'>£</span>
              <span >945</span>
            </div>
            <p>UI Design</p>
            <p>Up to 10 Pages</p>
            <p>Wordpress Blog</p>
            <button className='border-2 border-dark-color bg-dark-color hover:bg-shade-brand-color mt-10  mb-4 py-4 px-8 rounded-xl' onClick={handleScroll}>Contact me</button>
          </div>

          <div className='bg-mid-color rounded-lg py-4 px-2 w-full md:w-1/3  flex flex-col items-center justify-between shadow-md'>
            <h4 className='text-xl py-4 '>E-commerce</h4>
            <div className='text-md pt-1 pb-0 '>
              <span className='text-dark-color font-light px-1'>from</span>
            </div>
            <div className='flex text-5xl pb-4'>
              <span className='text-dark-color opacity-80 font-light px-1'>£</span>
              <span >1245</span>
            </div>
            <p>UI Design</p>
            <p>Up to 15 Pages</p>
            <p>Online Store (WooCommerce or Shopify)</p>
            <p></p>
            <button className='border-2 border-brand-color hover:bg-brand-color mt-10 mb-4  py-4 px-8 rounded-xl' onClick={handleScroll}>Contact me </button>
          </div>

        </div>


      </section>
  **/}
    
      <section ref={contactRef} className='p-10vh flex flex-col items-center'>
      <h2 className='text-light-color text-2xl  py-8 '>Contact</h2>
          <ContactForm/>


      </section>
    </>
  )
}

export default Web